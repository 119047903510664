import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";

import firebase from "../../config/firebase";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

import AppLayout from "../components/appLayout";

import { RadioGroup } from "@headlessui/react";
import { navigate } from "gatsby-link";

const plans = [
  {
    name: "40 threads",
    priceMonthly: 120,
    paddleId: 16756,
    limit: "Rotating Every Request, Unlimited Bandwidth",
  },
  {
    name: "80 threads",
    priceMonthly: 216,
    paddleId: 14614,
    limit: "Rotating Every Request, Unlimited Bandwidth",
  },
  {
    name: "150 threads",
    priceMonthly: 378,
    paddleId: 11254,
    limit: "Rotating Every Request, Unlimited Bandwidth",
  },
  {
    name: "250 threads",
    priceMonthly: 600,
    paddleId: 11254,
    limit: "Rotating Every Request, Unlimited Bandwidth",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BuyProxies = ({ favoriteColor }) => {
  const state = React.useContext(GlobalStateContext);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingPaddle, setLoadingPaddle] = useState(false);
  const [selected, setSelected] = useState({});

  // Make sure user is logged in
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setLoadingData(false);
      } else {
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (state.user) {
      if (
        state.user.subscriptions &&
        state.user.subscriptions.length > 0 &&
        state.user.subscriptions[0].paddlePlan
      ) {
        let getPlan = plans.find(
          (plan) => plan.paddleId == state.user.subscriptions[0].paddlePlan
        );

        setSelected(getPlan);
      }
    }
  }, [state.user]);

  const openPaddlePopup = () => {
    if (!loadingPaddle) {
      setLoadingPaddle(true);
      window.Paddle.Checkout.open({
        product: selected.paddleId,
        passthrough: JSON.stringify({
          billableId: state.user._id,
        }),
        loadCallback: function () {
          setLoadingPaddle(false);
        },
        closeCallback: function () {
          setLoadingPaddle(false);
        },
      });
    }
  };
  return (
    <>
      {!loadingData && (
        <AppLayout>
          <Helmet>
            <title>Buy Proxies</title>
          </Helmet>
          <div className="py-10">
            <header>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight text-gray-900">
                  Add Subscription Plan
                </h1>
              </div>
            </header>
            <main>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="py-8">
                  <div className="p-8 border border-gray-200 rounded-lg bg-white">
                    <div className="max-w-xl">
                      <RadioGroup value={selected} onChange={setSelected}>
                        <RadioGroup.Label className="sr-only">
                          Pricing plans
                        </RadioGroup.Label>
                        <div className="relative bg-white rounded-md -space-y-px">
                          {plans.map((plan, planIdx) => (
                            <RadioGroup.Option
                              key={plan.name}
                              value={plan}
                              className={({ checked }) =>
                                classNames(
                                  planIdx === 0
                                    ? "rounded-tl-md rounded-tr-md"
                                    : "",
                                  planIdx === plans.length - 1
                                    ? "rounded-bl-md rounded-br-md"
                                    : "",
                                  checked
                                    ? "bg-indigo-50 border-indigo-200 z-10"
                                    : "border-gray-200",
                                  "relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <div className="flex items-center text-sm">
                                    <span
                                      className={classNames(
                                        checked
                                          ? "bg-indigo-600 border-transparent"
                                          : "bg-white border-gray-300",
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-500"
                                          : "",
                                        "h-4 w-4 rounded-full border flex items-center justify-center"
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <RadioGroup.Label
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-indigo-900"
                                          : "text-gray-900",
                                        "ml-3 font-medium"
                                      )}
                                    >
                                      {plan.name}
                                    </RadioGroup.Label>
                                  </div>
                                  <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                                    <span
                                      className={classNames(
                                        checked
                                          ? "text-indigo-900"
                                          : "text-gray-900",
                                        "font-medium"
                                      )}
                                    >
                                      ${plan.priceMonthly} / mo
                                    </span>{" "}
                                    <span
                                      className={
                                        checked
                                          ? "text-indigo-700"
                                          : "text-gray-500"
                                      }
                                    ></span>
                                  </RadioGroup.Description>
                                  <RadioGroup.Description
                                    className={classNames(
                                      checked
                                        ? "text-indigo-700"
                                        : "text-gray-500",
                                      "ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                                    )}
                                  >
                                    {plan.limit}
                                  </RadioGroup.Description>
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={openPaddlePopup}
                        >
                          {loadingPaddle ? (
                            <>
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              Processing
                            </>
                          ) : (
                            <>{selected ? "Upgrade" : "Subscribe"}</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </AppLayout>
      )}
    </>
  );
};

export default BuyProxies;
